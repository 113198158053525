// Open Source Softwares logo
import bitcoinLogo from "../../assets/img/partners/bitcoin-logo.svg";
import minaLogo from "../../assets/img/partners/mina-logo.svg";
import aztecLogo from "../../assets/img/partners/aztec-logo.png";
import nearLogo from "../../assets/img/partners/near-logo.svg";

export const projects = [
  {
    title: "Proof of Kernel Work",
    description: "PoKW Node client written in Go, based on Geth and our academic paper.",
    githubIcon: true,
    link: "https://github.com/ExtropyIO/ProofOfKernelWork",
    buttonColor: "dark",
    buttonText: "See on Github",
  },
  {
    title: "Defi Bot",
    description: "Tutorial to write Defi arbitrage bots, based on our two series of Medium blog posts",
    githubIcon: true,
    link: "https://github.com/ExtropyIO/defi-bot",
    buttonColor: "dark",
    buttonText: "See on Github",
  },
];

export const openSourceInvolvement = [
  {
    img: bitcoinLogo,
    title: "Bitcoin",
    description: `
        Extropy runs a Bitcoin full node that verifies and relay transactions, and improve decentralisation of
        the Bitcoin network. In addition, running Bitcoin Lightning strengthens Lightning network node discovery
        and transaction routing.
    `,
  },
  {
    img: minaLogo,
    title: "MINA",
    description: `
        We have been involved in supporting Testnet 3.2, 3.3 and the Pickles Sandbox testnet for the Mina
        Protocol. We have now been selected to participate in the Genesis program.
    `,
  },
  {
    img: aztecLogo,
    title: "Aztec",
    description: "Extropy took part in the ignition ceremony of the Aztec protocol.",
  },
];
