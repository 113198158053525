export const videos = [
  {
    title: "Zero Knowledge Proofs - Laurence Kirk at Oxford University",
    description: "In this talk Laurence explains different types of ZKP, associated technologies and use cases.",
    embedId: "FGEIapq99xc",
  },
  {
    title: "Technical intro to blockchain part 2/2 - Laurence Kirk at Oxford Blockchain Society 2020",
    description:
      "In Part 2, we cover: 1) smart contracts introduction, 2) introduction to Solidity programming language, 3) coding in Solidity using EthereumRemix, Do's and Don'ts for programming with smart contracts",
    embedId: "zrDZtTSl-eI",
  },
  {
    title: "Technical intro to blockchain part 1/2 - Laurence Kirk at Oxford Blockchain Society 2020",
    description:
      "In the part 1 of this technical series Laurence will introduce concepts such as:  blockchains, cryptography, consensus mechanisms & programmable blockchains, decentralisation, trust, verification, resilience and open systems. This lays a great foundation for the rest of the series.",
    embedId: "EpRcc5hYE5k",
  },
  {
    title: "Proof of Kernel Work - Laurence Kirk at Imperial College London 2019",
    description:
      "Laurence Kirk's talk 'Proof of Kernel Work' from the May 2019 meetup at Imperial College London. Recorded during Ethereum London meetup",
    embedId: "Fn7KVyg-JFA",
  },
];

export const papers = [
  {
    title: "Proof of Kernel Work: a democratic low-energy consensus for distributed access-control protocols",
    preview: "",
    abstract:
      "We adjust the Proof of Work (PoW) consensus mechanism used in Bitcoin and Ethereum so that we can build on its strength while also addressing, in part, some of its perceived weaknesses. Notably, our work is motivated by the high energy consumption for mining PoW, and we want to restrict the use of PoW to a configurable, expected size of nodes, as a function of the local blockchain state. The approach we develop for this rests on three pillars: (i) Proof of Kernel Work (PoKW), a means of dynamically reducing the set of nodes that can participate in the solving of PoW puzzles such that an adversary cannot increase his attack surface because of such a reduction; (ii) Practical Adaptation of Existing Technology, a realization of this PoW reduction through an adaptation of existing blockchain and enterprise technology stacks; and (iii) Machine Learning for Adaptive System Resiliency, the use of techniques from artificial intelligence to make our approach adaptive to system, network and attack dynamics. We develop here, in detail, the first pillar and illustrate the second pillar through a real use case, a pilot project done with Porsche on controlling permissions to vehicle and data log accesses. We also discuss pertinent attack vectors for PoKW consensus and their mitigation. Moreover, we sketch how our approach may lead to more democratic PoKW-based blockchain systems for public networks that may inherit the resilience of blockchains based on PoW.",
    link: "https://royalsocietypublishing.org/doi/full/10.1098/rsos.180422",
  },
  {
    title: "Medixain : Robust Blockchain Optimization Enabling Individual Medical Wallet Architecture",
    preview: "#",
    abstract:
      "In todays rapidly aging societies further medical advancements more than ever rely on the automated analysis of vast amounts of data to receive more significant results of, e.g., medication effects. Particularly in medical research, it is evident how important such procedures are, keywords are, i.e., next generation sequencing or imaging techniques, yet, also medical practices strongly require such tooling. A major challenge in the health sector is demographic change, with an aging society and the resulting increased morbidity of the individual. ",
    link: "https://www.semanticscholar.org/paper/medixain-%3A-Robust-Blockchain-Optimization-Enabling-Schwerin-El-Kutbi/95ae2a6b5918674f408d43dffa1b8cdd7625864b?p2df",
  },
];

export const tutorials = [
  {
    image: require("../../assets/img/tutorials/solidity-basics.jpg"),
    title: "Solidity Basics",
    description: "Learn how to write your first Ethereum smart contract using Solidity.",
    link: "https://foundation.extropy.io/articles/write-first-smart-contract.html",
  },
  {
    image: require("../../assets/img/tutorials/zero-knowledge-proofs-theory.jpg"),
    title: "Math Primers for Zero Knowledge",
    description: "Discover the Mathematical primers behind the cryptography of Zero Knowledge Proof.",
    link: "https://maths.extropy.io/",
  },
];
