import laurence from "../../assets/img/team/laurence-kirk.jpg";
import karol from "../../assets/img/team/karol-podufalski.jpg";
import cosimo from "../../assets/img/team/cosimo-de-medici-di-toscana.jpg";

export const team = [
  {
    name: "Laurence Kirk",
    title: "CEO",
    profileImg: laurence,
    description: `
          After a successful career writing financial software I was captivated by the potential of blockchain
          technology. I set up Extropy.io, a consultancy specialising in blockchain and cryptography. I am a firm
          believer in community education and frequently run blockchain workshops, as well as teach at the University of
          Innsbruck Business Management School and Peking University HSBC Business School.
    `,
    linkedin: "https://www.linkedin.com/in/extropylaurence/",
  },
  {
    name: "Karol Podufalski",
    title: "Blockchain Engineer",
    profileImg: karol,
    description: `
        My passion for blockchain technology evolved during my engineering studies and software graduate programme after which I decided to pursue a career in this industry by joining Extropy.io. This allowed me to work hands on developing innovative ideas that can be implemented in real world.
    `,
    linkedin: "https://www.linkedin.com/in/karolpod/",
  },
  {
    name: "Cosimo De' Medici Di Toscana",
    title: "Blockchain Security Engineer",
    profileImg: cosimo,
    description: `
        Dedicated multilingual IT Security and Blockchain professional. Highly skilled in utilizing cutting edge technologies and security best practices to develop successful technical solutions.
    `,
    linkedin: "https://www.linkedin.com/in/demedicicosimo/",
  },
];
