import React, { useState } from "react";
import { Button } from "reactstrap";

// assets
import bgImage from "../../assets/img/facts_bg.jpg";
import minaLogo from "../../assets/img/partners/mina-logo.svg";

// data
import { projects, openSourceInvolvement } from "./projects";

// components
import Section from "../../components/Section";
import { MinaModal } from "./MinaModal";

const Facts = () => {
  const [minaModal, setMinaModal] = useState(false);

  const toggleMinaModal = () => setMinaModal(!minaModal);
  return (
    <Section id="facts">
      <div className="section-header pt-5 pb-5 text-center">
        <h2 className="section-title">
          <span>Open </span>Source
        </h2>
        <p className="section-subtitle mr-auto ml-auto">
          Discover our involvement and contribution to Open Source projects.
        </p>
      </div>
      {/* Open Source Projects */}
      <div class="mx-5 row justify-content-center">
        {projects.map((project) => {
          return (
            <div class="col-sm-3 mb-5">
              {project.githubIcon ? <i style={{ fontSize: "3.5rem" }} class="m-3 fab fa-github"></i> : ""}
              <h4 class="ml-3" style={{ display: "inline" }}>
                {project.title}
              </h4>
              <p class="mt-2">{project.description}</p>
              <Button color={project.buttonColor} href={project.link} target="_blank" onClick={project.clickHandler}>
                {project.buttonText}
              </Button>
            </div>
          );
        })}
        <div class="col-sm-3 mb-5">
          <img src={minaLogo} alt="MINA logo" class="m-3" style={{ width: "15%" }} />
          <h4 style={{ display: "inline" }}>Staking with Mina</h4>
          <p class="mt-2">
            We are running a node on the MINA network. You can stake your MINA tokens with us and get a share of the
            block rewards each time we validate a block.
          </p>
          <Button color="secondary-1" onClick={toggleMinaModal}>
            Instructions
          </Button>
        </div>
        <MinaModal modal={minaModal} toggle={toggleMinaModal} />
      </div>

      <div className="facts-container" style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="container-fluid pt-5 pb-4">
          {/* Open Source Involvement */}
          <div className="row justify-content-center">
            {openSourceInvolvement.map((involvement) => (
              <div className="col-sm-6 col-md-2 col-md-offset-1 text-center mb-4">
                <img src={involvement.img} alt="Open Source Projects logo" class="mb-3 partners-logo" />
                <h4 className="facts-counter text-light">{involvement.title}</h4>
                <p className="facts-title text-light">{involvement.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Facts;
