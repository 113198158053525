import React from "react";

// components
import Section from "../../components/Section";

const Services = () => {
  return (
    <Section id="services">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h2 className="section-title">
            <span>Our </span>Services
          </h2>
          <div className="section-subtitle mr-auto ml-auto">
            We offer auditing, software development services and technical workshops around Blockchain, Distributed
            Ledger Technology and Cryptography.
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="service-box d-flex">
              <div className="service-icon mr-4">
                <i className="fas fa-shield-alt icon-primary" />
              </div>
              <div className="service-body">
                <h4 className="service-title">Security Audits</h4>
                <p className="service-description">Guidance using industry best practices.</p>
                <p className="service-description">Audit reviews covering:</p>
                <ul className="service-description">
                  <li>Applications</li>
                  <li>Protocols</li>
                  <li>Automated tests</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="service-box d-flex">
              <div className="service-icon mr-4">
                <i className="fas fa-code icon-secondary-1" />
              </div>
              <div className="service-body">
                <h4 className="service-title">Development</h4>
                <p className="service-description">Blockchain application development.</p>
                <p className="service-description">Expertise including:</p>
                <ul className="service-description">
                  <li>Ethereum</li>
                  <li>Zero Knowledge systems design</li>
                  <li>Blockchain research</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="service-box d-flex">
              <div className="service-icon mr-4">
                <i className="fas fa-chalkboard-teacher icon-secondary-2" />
              </div>
              <div className="service-body">
                <h4 className="service-title">Training</h4>
                <p className="service-description">Technical and business courses.</p>
                <p className="service-description">Workshops teaching:</p>
                <ul className="service-description">
                  <li>Smart contract development</li>
                  <li>Zero Knowledge Proofs</li>
                  <li>Advanced EVM</li>
                  <li>Business aspects of the Blockchain</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Services;
