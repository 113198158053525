import React from "react";

// data
import { socials } from "../navigation";

const topBar = () => {
  return (
    <div className="top-bar pt-1 pb-1 text-light">
      <div className="container">
        <div className="d-flex justify-content-between">
          <div className="d-flex pt-2 pb-2">
            <div className="d-flex align-item-center mr-3">
              <a href="tel:1865-261-424p44" className="text-light">
                <i className="fas fa-phone-alt mr-2" />
                <small>+44 (0) 1865 338 228</small>
              </a>
            </div>
            <div className="d-flex align-item-center">
              <a href="mailto:info@extropy.io" className="text-light">
                <i className="fas fa-envelope mr-2" />
                <small>info@extropy.io</small>
              </a>
            </div>
          </div>
          <div className="d-flex pt-2 pb-2">
            <div className="social-media d-flex align-item-center">
              {socials.map((social) => (
                <a href={social.link} className="text-light" target="_blank" rel="noopener noreferrer">
                  <i className={"fab " + social.icon + " mr-4"} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default topBar;
